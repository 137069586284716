import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { NewsArticlePageTemplate } from "./news-article"

const NewsArticlePage = ({
  data: {
    markdownRemark: { frontmatter, html },
  },
}) => {
  return (
    <Layout>
      <Seo
        title={frontmatter.title}
        description={frontmatter.description}
        meta={
          frontmatter.tags?.length > 0
            ? [
                {
                  name: `keywords`,
                  content: frontmatter.tags?.join(),
                },
              ]
            : []
        }
      />
      <NewsArticlePageTemplate {...frontmatter} html={html} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $langKey: String!) {
    markdownRemark(fields: { slug: { eq: $slug }, langKey: { eq: $langKey } }) {
      frontmatter {
        title
        description
        author {
          name
          role
        }
        tags
        date
      }
      html
    }
  }
`

export default NewsArticlePage
