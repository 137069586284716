import { Content, HTMLContent } from "../../components/content"
import React from "react"
import FancyUnderline from "../../components/fancy-underline"
import PropTypes from "prop-types"
import { FormattedDate } from "react-intl"

const TitleSection = ({ title, date }) => {
  return (
    <section className="container mx-auto mt-4 px-5 text-center">
      <h1 className="mb-4">{title}</h1>
      {!!date && (
        <div className="inline-block relative pb-2">
          <p className="font-extralight">
            <FormattedDate value={date} dateStyle="full" />
          </p>
          <FancyUnderline />
        </div>
      )}
    </section>
  )
}

TitleSection.defaultProps = {
  title: "",
}

TitleSection.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
}

const ArticleSection = ({ html, contentComponent }) => {
  const ContentComponent = contentComponent || Content
  return (
    <article className="container mx-auto px-5 mt-8 markdown text-lg lg:w-2/3">
      <ContentComponent content={html} />
    </article>
  )
}

ArticleSection.propTypes = {
  html: PropTypes.string,
  contentComponent: PropTypes.func,
}

const FooterSection = ({ author, tags }) => {
  return (
    <section className="container mx-auto px-5 my-8 lg:w-2/3">
      {!!author && (
        <p className="text-lg">
          <span className="font-bold">{author.name || ""}</span>
          <br />
          {author.role || ""}
        </p>
      )}
      {!!tags && (
        <p className="space-x-4">
          {tags.map(tag => (
            <button
              key={tag}
              className="border-2 py-2 px-4 text-sm font-semibold border-black pointer-events-none focus:outline-none"
            >
              #{tag}
            </button>
          ))}
        </p>
      )}
    </section>
  )
}

FooterSection.propTypes = {
  author: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.string),
}

export const NewsArticlePageTemplate = ({
  title,
  date,
  author,
  tags,
  html,
  preview,
}) => {
  return (
    <>
      <TitleSection title={title} date={date} />
      <ArticleSection html={html} contentComponent={!preview && HTMLContent} />
      <FooterSection author={author} tags={tags} />
    </>
  )
}
